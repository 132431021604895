<script setup lang="ts">
  import { useStoreVip } from '~/stores/useStoreVip'
  import type { ContentAlignement } from '~/types/design-system'

  const { companyId } = useRoute().params
  const { data: vipLevels, isPending } = useStoreVip().getVipLevels(parseInt(companyId as string))
  const sizes = useMonitorSize()

  const { isAuthenticated } = storeToRefs(useStoreAuth())

  const section = computed(() => {
    const { brandings } = useStoreBranding()
    if (!brandings?.branding_vip) return

    const { extractTypography } = useBrandingTypography()
    const {
      id,
      section_color,
      section_title: { alignment }
    } = brandings.branding_vip

    return {
      id,
      backgroundColor: section_color,
      title: extractTypography('branding_vip', 'section_title', 'primary'),
      alignment
    }
  })

  const titleAlignment = computed((): ContentAlignement => {
    if (!section.value) return 'left'

    return getContentAlignment(section.value.alignment)
  })
</script>

<template>
  <SectionAnchored anchor-id="vip">
    <SectionBase
      v-if="section"
      :id="section.id"
      class="vip-wrapper"
      :background-color="section.backgroundColor"
    >
      <SectionSkeletonTitle v-if="isPending" />
      <div
        v-else
        class="vip-wrapper__title"
      >
        <TypographyBase
          :id="section.title.id"
          tag="h2"
          class="vip-wrapper__title--text text-primary-heading-2"
          :font-weight="section.title.fontWeight"
          :font-size="section.title.fontSize"
          :color="section.title.color"
          :text-align="section.title.textAlign"
          :font-family="section.title.fontFamily"
        >
          {{ section.title.text }}
        </TypographyBase>
      </div>

      <SectionVipProgress
        v-if="vipLevels && isAuthenticated"
        :vip-levels="vipLevels"
      />

      <CarouselBase
        v-if="sizes.isMobile.value"
        class="vip-wrapper__carousel"
        buttons-background-color="#000"
        buttons-hover-background-color="#000"
        buttons-text-color="#fff"
        buttons-hover-text-color="#fff"
        :buttons-radius="0"
        :slide-width="300"
      >
        <template v-if="isPending">
          <SectionVipCardSkeleton />
          <SectionVipCardSkeleton />
          <SectionVipCardSkeleton />
        </template>
        <SectionVipCardList
          v-else-if="vipLevels && vipLevels.length > 0"
          :vip-levels="vipLevels"
        />
      </CarouselBase>
      <div
        v-else
        class="vip-wrapper__container"
      >
        <template v-if="isPending">
          <SectionVipCardSkeleton />
          <SectionVipCardSkeleton />
          <SectionVipCardSkeleton />
        </template>
        <SectionVipCardList
          v-else-if="vipLevels && vipLevels.length > 0"
          :vip-levels="vipLevels"
        />
      </div>
    </SectionBase>
  </SectionAnchored>
</template>

<style scoped>
  .vip-wrapper {
    :deep(.lds-section__content) {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 768px) {
        gap: 40px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: v-bind(titleAlignment);

      &--text {
        width: auto;
      }

      &--button {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 20px;
        padding-left: 0;
        padding-right: 4px;
        flex-shrink: 0;
      }
    }

    &__carousel {
      height: 380px; /* card height */
    }

    &__container {
      display: grid;
      place-content: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (min-width: 480px) {
        gap: 10px;
      }

      @media (min-width: 768px) {
        gap: 70px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }

      @media (min-width: 1440px) {
        gap: 40px;
      }
    }
  }
</style>
